import "./CardDetails.css";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePrismicDocumentByUID } from "@prismicio/react";
import { NotFound } from "./NotFound";
import { PrismicText, PrismicRichText } from "@prismicio/react";

function CardDetails(props) {
  const { uid } = useParams();
  const [prismicDoc, prismicDocState] = usePrismicDocumentByUID(
    "microcontent",
    uid
  );
  const notFound = prismicDocState.state === "failed";

  useEffect(() => {
    if (prismicDocState.state === "failed") {
      console.warn(
        "Blog post document was not found. Make sure it exists in your Prismic repository"
      );
    }
  }, [prismicDocState.state]);

  if (prismicDoc) {
    prismicDoc.data.pages.map((item, index) => {
      if (item.embed_content.html) {
        item.embed_content.html = item.embed_content.html.replace("width=\"200\" height=\"113\"", "class=\"youtube_video\"");
      }
      return item;
    });
    console.log("printing doc", prismicDoc);
    return (
      <div className="app-post">
        <div className="nav-bar">
          <div className="container nav-container">
            <a
              href="https://www.peakperformer.io/"
              aria-current="page"
              className="brand w-nav-brand w--current"
              aria-label="home"
            >
              <img
                src="https://web.peakperformer.io/static/media/logo-white.4bfd63b3.svg"
                alt=""
                className="nav-logo"
              />
            </a>
          </div>
        </div>
        <div className="card-details">
          <PrismicRichText field={prismicDoc.data.content_title} />

          <div className="inpsired_by">
            Inspired by: <PrismicRichText field={prismicDoc.data.inspired_by} />
          </div>
          <div className="duration">
            {prismicDoc.data.duration_in_minutes} min read
          </div>
          <div className="topics">
            {prismicDoc.tags.map((item, index) => (
              <span className="topic">{item}</span>
            ))}
          </div>

          <h3>What you will learn?</h3>
          <PrismicRichText field={prismicDoc.data.what_will_you_learn_} />
          <h3>What are the key takeaways?</h3>
          <PrismicRichText
            field={prismicDoc.data.what_are_the_key_takeaways_}
          />

          <div className="highlights">
            <div className="highlight_container">
              {prismicDoc.data.pages.map((item, index) => (
                <div className="highlight">{item.page_title[0].text}</div>
              ))}
            </div>
          </div>
          <div>
            {prismicDoc.data.pages.map((item, index) => (
              <div key={index} className="pages">
                <h2>
                  <PrismicText field={item.page_title} />
                </h2>
                <div>
                  <PrismicRichText field={item.page_content} />
                </div>
                {
                  item.page_image.url ?
                    <img className="page_image" alt="peakperformer" src={item.page_image.url} />
                    : null
                }
                {
                  item.page_external_link.url ?
                    <div className="external_link">
                      <a href={item.page_external_link.url} target="_blank" rel="noreferrer">Click Here</a>
                    </div>
                    : null
                }
                {
                  item.page_external_video_link.url ?
                    <div>
                      <video className="external_video" controls>
                        <source src={item.page_external_video_link.url} type="video/mp4" />
                      </video>
                    </div>
                    : null
                }
                {
                  item.embed_content.html ?
                    <div>
                      <div className="embed_video"
                        dangerouslySetInnerHTML={{
                          __html: item.embed_content.html,
                        }}
                      />
                    </div>
                    : null
                }
                {
                  item.page_external_audio_link.url ?
                    <div>
                      <audio className="audio_link" controls>
                        <source src={item.page_external_audio_link.url} type="video/mp4" />
                      </audio>
                    </div>
                    : null
                }
              </div>
            ))}
          </div>
        </div>
        <div className="footer footer-width">
          <div className="footer-text font-text-size">
            Leverage coaching to unlock your peak performance
          </div>
          <div>
            <a href='http://onelink.to/rayj8b'>
              <img className="playstore-button" src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' alt='Get it on Google Play' /></a>
          </div>
          <div>
            <a href="http://onelink.to/rayj8b" >
              <img className="appstore-button" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1634688000&h=31d240d22c97a1fc66a6cb73c4ae7d84" alt="Download on the App Store" /></a>
          </div>
        </div>
      </div>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
}

export default CardDetails;
