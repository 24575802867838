import "./Home.css";
import { NotFound } from "./NotFound";

function Home() {
  return (
    <div className="app-home">
      <div className="nav-bar">
        <div className="container nav-container">
          <a
            href="https://www.peakperformer.io/"
            aria-current="page"
            className="brand w-nav-brand w--current"
            aria-label="home"
          >
            <img
              src="https://web.peakperformer.io/static/media/logo-white.4bfd63b3.svg"
              alt=""
              className="nav-logo"
            />
          </a>
        </div>
      </div>
      <NotFound />
      <div className="footer footer-width">
        <div className="footer-text font-text-size">
          Leverage coaching to unlock your peak performance
        </div>
        <div>
          <a href='http://onelink.to/rayj8b'>
            <img className="playstore-button" src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' alt='Get it on Google Play' /></a>
        </div>
        <div>
          <a href="http://onelink.to/rayj8b" >
            <img className="appstore-button" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1634688000&h=31d240d22c97a1fc66a6cb73c4ae7d84" alt="Download on the App Store" /></a>
        </div>
      </div>
    </div>
  );
}

export default Home;
