import * as prismic from "@prismicio/client";

// Fill in your repository name
export const repositoryName = "peak-performer";
const endpoint = prismic.getEndpoint(repositoryName);

export const client = prismic.createClient(endpoint, {
  // If your repo is private, add an access token
  accessToken:
    "MC5ZakJnc1JJQUFDOEFzaTdD.77-9cO-_ve-_ve-_vWQ0Ge-_vUXvv70Tb--_vUIFdCpq77-977-9AO-_ve-_ve-_vXNRJUPvv73vv73vv70",

  // This defines how you will structure URL paths in your project.
  // Update the types to match the Custom Types in your project, and edit
  // the paths to match the routing in your project.
  //
  // If you are not using a router in your project, you can change this
  // to an empty array or remove the option entirely.
  routes: [
    {
      type: "microcontent",
      path: "/post/:uid",
    },
  ],
});
