import { Routes, BrowserRouter, Route, Link } from "react-router-dom";

import { PrismicProvider } from "@prismicio/react";
import { client } from "./prismic";
import CardDetials from "./pages/CardDetails";
import { NotFound } from "./pages/NotFound";
import Home from "./pages/Home";

function App() {
  return (
    <PrismicProvider
      client={client}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/post/:uid" element={<CardDetials />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </PrismicProvider>
  );
}

export default App;
